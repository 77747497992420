module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","background_color":"#003564","theme_color":"#003564","display":"minimal-ui","icon":"src/images/tabula-icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"52c93f6251237ed3874463ed1378dbc1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NDNQ3MN","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"lang":"en","siteId":1821761,"enableCMP":true,"consentOnScroll":false,"consentOnLinkAndButton":false,"consentOnElement":null,"ccpaAcknowledgeOnDisplay":true,"floatingPreferencesButtonDisplay":"top-right","floatingPreferencesButtonColor":"#212121","googleAdsPreferenceManagement":true,"enableCcpa":true,"countryDetection":true,"cookiePolicyId":14010178,"banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"bottom","rejectButtonDisplay":true}},"googleTagManagerOptions":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
